import React from 'react';
import { Clock, ArrowRight } from 'lucide-react';

const RandomRecipes = ({ recipes }) => {
    // Format timestamp to relative time (e.g., "2 hours ago")
    const getRelativeTime = (timestamp) => {
        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
        const now = new Date();
        const diff = new Date(timestamp) - now;

        const minutes = Math.round(diff / 1000 / 60);
        const hours = Math.round(minutes / 60);
        const days = Math.round(hours / 24);

        if (Math.abs(minutes) < 60) return rtf.format(minutes, 'minute');
        if (Math.abs(hours) < 24) return rtf.format(hours, 'hour');
        return rtf.format(days, 'day');
    };

    return (
        <div className="mt-12 mb-8 print:hidden">
            <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white flex items-center">
                Recipes saved by others
            </h3>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {recipes.map((recipe, index) => (
                    <div
                        key={index}
                        className="group relative bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700
                       rounded-lg p-2 hover:shadow-md transition-all duration-200"
                    >
                        <h4 className="font-medium text-gray-900 dark:text-gray-100 mb-1 line-clamp-1">
                            {recipe.title}
                        </h4>

                        <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                            <Clock size={14} className="mr-1" />
                            <span>{getRelativeTime(recipe.scannedAt)}</span>
                        </div>

                        <a
                            href={`/recipe/${recipe._id}`}
                            className="absolute inset-0 flex items-center justify-end p-4 opacity-0
                       group-hover:opacity-100 transition-opacity duration-200"
                        >
                            <span className="sr-only">View recipe</span>
                            <ArrowRight className="w-5 h-5 text-blue-500 dark:text-blue-400" />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RandomRecipes;