import React from 'react';
import { Check } from 'lucide-react';

export const RecipeInstructions = ({ instructions, ingredients }) => {
    const [completedSteps, setCompletedSteps] = React.useState(new Set());
    const [activeStep, setActiveStep] = React.useState(null);

    const toggleStep = (index) => {
        const newCompleted = new Set(completedSteps);
        if (newCompleted.has(index)) {
            newCompleted.delete(index);
        } else {
            newCompleted.add(index);
            for (let i = 0; i < index; i++) {
                newCompleted.add(i);
            }
        }
        setCompletedSteps(newCompleted);
    };

    const getStepNumbering = (index) => {
        const number = index + 1;
        if (number < 10) return `0${number}`;
        return number;
    };

    // Get ingredients for a specific step
    const getStepIngredients = (stepNumber) => {
        return ingredients.filter(ing => parseInt(ing.step) === stepNumber + 1);
    };

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700">
            <div className="p-4 border-b border-gray-100 dark:border-gray-700">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center gap-2">
                    Instructions
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-300">
                        ({instructions.length} steps)
                    </span>
                </h3>
            </div>

            <div className="divide-y divide-gray-100 dark:divide-gray-700">
                {instructions.map((step, index) => {
                    const stepIngredients = getStepIngredients(index);
                    return (
                        <div
                            key={index}
                            className={`relative ${
                                activeStep === index ? 'bg-blue-50 dark:bg-blue-900' : 'hover:bg-gray-50 dark:hover:bg-gray-600'
                            } transition-colors duration-200`}
                        >
                            <div className="p-4 sm:p-5">
                                <div className="flex gap-4">
                                    <div className="flex-shrink-0">
                                        <button
                                            onClick={() => toggleStep(index)}
                                            className={`w-8 h-8 rounded-full flex items-center justify-center border-2 transition-all duration-200 ${
                                                completedSteps.has(index)
                                                    ? 'bg-green-500 border-green-500 text-white'
                                                    : 'border-gray-300 dark:border-gray-500 text-gray-400 dark:text-gray-300 hover:border-gray-400 dark:hover:border-gray-400'
                                            }`}
                                        >
                                            {completedSteps.has(index) ? (
                                                <Check size={16} />
                                            ) : (
                                                <span className="font-medium text-blue-900 dark:text-blue-400">{getStepNumbering(index)}</span>
                                            )}
                                        </button>
                                    </div>

                                    <div className="flex-grow">
                                        <div
                                            className={`text-base leading-relaxed ${
                                                completedSteps.has(index)
                                                    ? 'text-gray-400 line-through dark:text-gray-500'
                                                    : 'text-gray-900 dark:text-gray-200'
                                            }`}
                                        >
                                            {step}
                                        </div>

                                        {/*{stepIngredients.length > 0 && (*/}
                                        {/*    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">*/}
                                        {/*        Ingredients: {stepIngredients.map(ing => ing.ingredient).join(', ')}*/}
                                        {/*    </div>*/}
                                        {/*)}*/}

                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {(completedSteps.size > 0 || activeStep !== null) && (
                <div className="p-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-100 dark:border-gray-600 text-sm">
                    <div className="flex justify-between items-center text-gray-500 dark:text-gray-300">
                        <span>
                            {completedSteps.size} of {instructions.length} steps completed
                        </span>
                        {completedSteps.size > 0 && (
                            <button
                                onClick={() => {
                                    setCompletedSteps(new Set());
                                    setActiveStep(null);
                                }}
                                className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 font-medium"
                            >
                                Reset Progress
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecipeInstructions;
