import React from 'react';
import { Check, ShoppingCart } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

export const RecipeIngredients = ({ ingredients, useMetric, convertToMetric }) => {
    const [checkedIngredients, setCheckedIngredients] = React.useState(new Set());
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    React.useEffect(() => {
        // Check if user has authentication token
        const token = localStorage.getItem('recipeToken');
        setIsLoggedIn(!!token);
    }, []);

    const toggleIngredient = (index) => {
        const newChecked = new Set(checkedIngredients);
        if (newChecked.has(index)) {
            newChecked.delete(index);
        } else {
            newChecked.add(index);
        }
        setCheckedIngredients(newChecked);
    };

    const renderIngredient = (ing) => {
        if (!ing.amount && !ing.unit) return ing.ingredient;

        let { amount, unit } = ing;
        if (useMetric && unit) {
            ({ amount, unit } = convertToMetric(amount, unit));
        }

        return (
            <>
            <span className="font-medium text-blue-900 dark:text-blue-400">
                {amount && `${amount} `}
                {unit && `${unit} `}
            </span>
                <span className="text-gray-900 dark:text-gray-200">{ing.ingredient}</span>
            </>
        );
    };

    // Sort ingredients by step number
    const sortedIngredients = [...ingredients].sort((a, b) => {
        // Handle cases where step might be undefined
        const stepA = a.step || 0;
        const stepB = b.step || 0;
        return stepA - stepB;
    });

    const addToShoppingList = async (ingredients) => {
        try {
            // Filter out checked ingredients
            const uncheckedIngredients = ingredients.filter((_, index) => !checkedIngredients.has(index));

            // Transform ingredients into shopping list format
            const shoppingItems = uncheckedIngredients.map(ing => {
                // Basic quantity normalization
                let quantity = 1;
                if (ing.amount) {
                    quantity = parseFloat(ing.amount);
                    if (isNaN(quantity)) quantity = 1;
                }

                // Determine category based on ingredient type (you might want to enhance this logic)
                const category = categorizeIngredient(ing.ingredient);

                return {
                    name: normalizeIngredientName(ing.ingredient),
                    quantity,
                    unit: normalizeUnit(ing.unit),
                    category
                };
            });

            const response = await fetch(`${API_URL}/shopping-list/bulk-add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('recipeToken')}`
                },
                body: JSON.stringify({ items: shoppingItems })
            });

            if (response.ok) {
                // Show success message
                alert('Items added to shopping list!');
            }
        } catch (error) {
            console.error('Error adding to shopping list:', error);
            alert('Failed to add items to shopping list');
        }
    };

    const categorizeIngredient = (ingredient) => {
        const lowerIngredient = ingredient.toLowerCase();

        // Dairy & Eggs
        if (/milk|cheese|cream|yogurt|butter|egg|sour cream|buttermilk|whey|cottage cheese|ricotta|mozzarella|cheddar|parmesan|custard|ice cream|ghee|margarine|kefir/.test(lowerIngredient))
            return 'Dairy & Eggs';

        // Meat & Poultry
        if (/beef|chicken|pork|lamb|turkey|duck|veal|venison|goat|ham|bacon|sausage|salami|pepperoni|prosciutto|steak|ribs|meatball|mince|ground meat/.test(lowerIngredient))
            return 'Meat & Poultry';

        // Seafood
        if (/fish|salmon|tuna|cod|shrimp|prawn|crab|lobster|mussel|clam|oyster|scallop|squid|octopus|anchovy|sardine|tilapia|halibut|mackerel|seabass/.test(lowerIngredient))
            return 'Seafood';

        // Fresh Produce - Vegetables
        if (/lettuce|tomato|cucumber|carrot|broccoli|spinach|kale|cabbage|onion|garlic|potato|celery|pepper|asparagus|zucchini|eggplant|cauliflower|mushroom|corn|pea/.test(lowerIngredient))
            return 'Vegetables';

        // Fresh Produce - Fruits
        if (/apple|banana|orange|grape|strawberry|blueberry|raspberry|blackberry|pear|peach|plum|mango|pineapple|watermelon|melon|kiwi|fig|date|cherry|lemon|lime/.test(lowerIngredient))
            return 'Fruits';

        // Bakery
        if (/bread|bun|roll|bagel|croissant|muffin|cake|cookie|pastry|pie|donut|tortilla|pita|baguette|brioche|biscuit|scone|waffle|pancake|crepe/.test(lowerIngredient))
            return 'Bakery';

        // Beverages
        if (/water|juice|soda|wine|beer|coffee|tea|milk|smoothie|cocktail|punch|lemonade|cider|ale|liquor|vodka|whiskey|rum|gin|champagne/.test(lowerIngredient))
            return 'Beverages';

        // Pantry - Dry Goods
        if (/flour|sugar|rice|pasta|cereal|oat|quinoa|couscous|noodle|bean|lentil|chickpea|breadcrumb|cornmeal|baking powder|baking soda|yeast|cornstarch/.test(lowerIngredient))
            return 'Dry Goods';

        // Pantry - Seasonings & Spices
        if (/salt|pepper|spice|herb|seasoning|cinnamon|cinnamon sticks|cloves|nutmeg|oregano|basil|thyme|rosemary|cumin|paprika|curry|chili|ginger|garlic powder|vanilla|bay leaf/.test(lowerIngredient))
            return 'Seasonings & Spices';

        // Pantry - Condiments & Sauces
        if (/oil|vinegar|sauce|dressing|mayonnaise|mustard|ketchup|salsa|honey|syrup|jam|jelly|peanut butter|soy sauce|hot sauce|bbq sauce|teriyaki|worcestershire/.test(lowerIngredient))
            return 'Condiments & Sauces';

        // Canned & Jarred Goods
        if (/canned|jarred|preserved|soup|broth|stock|tomato sauce|beans|tuna|pickle|olive|artichoke|corn|pea|bamboo|water chestnut/.test(lowerIngredient))
            return 'Canned & Jarred';

        // Snacks & Confectionery
        if (/chip|crisp|pretzel|popcorn|nut|seed|candy|chocolate|cookie|cracker|granola|trail mix|dried fruit|energy bar|gummy|lollipop|candy bar/.test(lowerIngredient))
            return 'Snacks & Confectionery';

        // Frozen Foods
        if (/frozen|ice cream|popsicle|tv dinner|frozen pizza|frozen vegetable|frozen fruit|frozen meal|frozen fish|frozen meat/.test(lowerIngredient))
            return 'Frozen Foods';

        // Health Foods
        if (/protein powder|vitamin|supplement|superfood|probiotics|almond milk|soy milk|tofu|tempeh|seitan|kombucha|health bar|meal replacement/.test(lowerIngredient))
            return 'Health Foods';

        // International & Ethnic Foods
        if (/kimchi|sushi|nori|miso|tahini|hummus|falafel|curry paste|wasabi|sriracha|gochujang|harissa|tzatziki|naan|tortilla|dumpling|pasta/.test(lowerIngredient))
            return 'International Foods';

        return 'Other';
    };


    const normalizeIngredientName = (name) => {
        // Remove quantities and measurements from the name
        return name
    };

    const normalizeUnit = (unit) => {
        if (!unit) return 'unit';

        // Map common units to standard forms
        const unitMap = {
            'cups': 'cup',
            'tablespoons': 'tbsp',
            'teaspoons': 'tsp',
            'ounces': 'oz',
            'pounds': 'lb',
            'grams': 'g',
            'milliliters': 'ml',
            'liters': 'l'
        };

        return unitMap[unit.toLowerCase()] || unit;
    };


    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700">
            <div className="p-4 border-b border-gray-100 dark:border-gray-700">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Ingredients
                        </h3>
                        <span className="text-sm font-normal text-gray-400 dark:text-gray-300">
                        ({ingredients.length})
                    </span>
                    </div>
                    {isLoggedIn && (
                        <button
                            onClick={() => addToShoppingList(ingredients)}
                            className="
 print:hidden
    flex items-center gap-2 px-4 py-2 rounded-lg font-medium
    bg-cyan-600
    hover:bg-cyan-700
    text-white
    transform transition-all duration-200
    hover:scale-105
    dark:bg-slate-700
    dark:hover:bg-slate-600
  "

                        >
                            <ShoppingCart className="h-4 w-4"/>
                            <span>Add to List</span>
                        </button>
                    )}
                </div>
            </div>

            <ul className="divide-y divide-gray-100 dark:divide-gray-700">
                {sortedIngredients.map((ing, index) => (
                    <li key={index}>
                        <label
                            className="flex items-start gap-3 p-3 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors cursor-pointer group rounded-lg">
                            <div className="flex-shrink-0 pt-1">
                                <div
                                    onClick={() => toggleIngredient(index)}
                                    className={`w-5 h-5 rounded border transition-all duration-200 flex items-center justify-center
                                    ${checkedIngredients.has(index)
                                        ? 'bg-gradient-to-r from-cyan-500 to-blue-500 border-transparent'
                                        : 'border-gray-300 dark:border-gray-500 group-hover:border-gray-400 dark:group-hover:border-gray-400'
                                    }`}
                                >
                                    {checkedIngredients.has(index) && (
                                        <Check size={14} className="text-white" />
                                    )}
                                </div>
                            </div>

                            <input
                                type="checkbox"
                                className="sr-only"
                                checked={checkedIngredients.has(index)}
                                onChange={() => toggleIngredient(index)}
                            />

                            <span className={`flex-grow leading-relaxed ${
                                checkedIngredients.has(index)
                                    ? 'text-gray-700 line-through dark:text-gray-400'
                                    : 'text-gray-700 dark:text-gray-400'
                            }`}>
                            {renderIngredient(ing)}
                        </span>
                        </label>
                    </li>
                ))}
            </ul>

            {checkedIngredients.size > 0 && (
                <div className="p-3 bg-gray-100 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 text-sm text-gray-500 dark:text-gray-200">
                    {checkedIngredients.size} of {ingredients.length} ingredients checked
                </div>
            )}
        </div>
    );

};

export default RecipeIngredients;