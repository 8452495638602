import React from 'react';
import { Moon, Sun } from 'lucide-react';

const ThemeToggle = ({ isDark, onToggle }) => {
    return (
        <button
            onClick={onToggle}
            className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors print:hidden"
            aria-label={isDark ? "Switch to light mode" : "Switch to dark mode"}
        >
            {isDark ? (
                <Sun className="h-5 w-5 text-gray-600 dark:text-gray-400" />
            ) : (
                <Moon className="h-5 w-5 text-gray-600 dark:text-gray-400" />
            )}
        </button>
    );
};

export default ThemeToggle;