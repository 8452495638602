import React from 'react';
import { X as CloseIcon } from 'lucide-react';

const FavoriteRecipe = ({ favorites, handleHistoryClick, removeFromFavorites }) => {
    return (
        <div className="recipe-favorites mt-8 print:hidden">
            <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
                Favorite Recipes
            </h3>
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {favorites.map((favoriteItem, index) => (
                    <li
                        key={index}
                        className="border border-gray-200 dark:border-gray-700 rounded-lg p-2
                                    hover:bg-gray-50 dark:hover:bg-gray-800
                                    bg-white dark:bg-gray-900
                                    flex items-stretch"
                    >
                        <button
                            onClick={() => handleHistoryClick(favoriteItem)}
                            className="flex-grow flex flex-col items-start text-left focus:outline-none overflow-hidden"
                        >
                            <h4 className="font-semibold text-blue-600 dark:text-blue-400 hover:underline w-full truncate">
                                {favoriteItem.title}
                            </h4>
                            <p className="text-sm text-gray-500 dark:text-gray-400 w-full truncate">
                                {favoriteItem.url}
                            </p>
                        </button>
                        <button
                            onClick={() => removeFromFavorites(favoriteItem.url)}
                            className="ml-2 p-1 text-gray-500 hover:text-red-500
                                        dark:text-gray-400 dark:hover:text-red-400
                                        focus:outline-none self-start"
                            aria-label="Remove from favorites"
                        >
                            <CloseIcon size={16}/>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FavoriteRecipe;