import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, Home, ListChecks, LogIn, LogOut } from 'lucide-react';

const Navigation = ({ user, onLogin, onLogout }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    const menuItems = [
        { path: '/', label: 'Home', icon: Home },
        { path: '/shopping-list', label: 'Shopping List', icon: ListChecks },
    ];

    return (
        <nav className="relative z-50 print:hidden">
            {/* Mobile menu button */}
            <div className="sm:hidden">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="p-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 rounded-md"
                >
                    {isOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
            </div>

            {/* Desktop navigation */}
            <div className="hidden sm:flex items-center space-x-6">
                {menuItems.map(({ path, label, icon: Icon }) => (
                    <Link
                        key={path}
                        to={path}
                        className={`flex items-center gap-2 px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                            isActive(path)
                                ? 'bg-cyan-50 dark:bg-cyan-900 text-cyan-700 dark:text-cyan-300'
                                : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700'
                        }`}
                    >
                        <Icon size={18} />
                        {label}
                    </Link>
                ))}
                <button
                    onClick={user ? onLogout : onLogin}
                    className="flex items-center gap-2 px-3 py-2 rounded-md text-sm font-medium transition-colors text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                    {user ? (
                        <>
                            <LogOut size={18} />
                            <span>Logout</span>
                        </>
                    ) : (
                        <>
                            <LogIn size={18} />
                            <span>Login</span>
                        </>
                    )}
                </button>
            </div>

            {/* Mobile menu */}
            <div
                className={`${
                    isOpen ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'
                } sm:hidden absolute top-full left-100 right-0 transition-all duration-300 ease-in-out bg-white dark:bg-gray-900 shadow-lg rounded-lg mt-2 overflow-hidden border border-gray-200 dark:border-gray-700`}
            >
                <div className="p-2 space-y-1">
                    {menuItems.map(({ path, label, icon: Icon }) => (
                        <Link
                            key={path}
                            to={path}
                            onClick={() => setIsOpen(false)}
                            className={`flex items-center gap-2 px-4 py-3 rounded-md text-sm font-medium transition-colors ${
                                isActive(path)
                                    ? 'bg-cyan-50 dark:bg-cyan-900 text-cyan-700 dark:text-cyan-300'
                                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700'
                            }`}
                        >
                            <Icon size={18} />
                            {label}
                        </Link>
                    ))}
                    <button
                        onClick={() => {
                            user ? onLogout() : onLogin();
                            setIsOpen(false);
                        }}
                        className="w-full flex items-center gap-2 px-4 py-3 rounded-md text-sm font-medium transition-colors text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                        {user ? (
                            <>
                                <LogOut size={18} />
                                <span>Logout</span>
                            </>
                        ) : (
                            <>
                                <LogIn size={18} />
                                <span>Login</span>
                            </>
                        )}
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;