// UserContext.js
import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const API_URL = process.env.REACT_APP_API_URL;

    const [user, setUser] = useState(null);
    const [remainingRequests, setRemainingRequests] = useState(0);
    const [isDark, setIsDark] = useState(() => {
        const saved = localStorage.getItem('darkMode');
        return saved ? JSON.parse(saved) : window.matchMedia('(prefers-color-scheme: dark)').matches;
    });
    const [showGoogleLogin, setShowGoogleLogin] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('recipeToken');
        setUser(null);
        setRemainingRequests(0);
    };

    useEffect(() => {
        const validateExistingToken = async () => {
            const token = localStorage.getItem('recipeToken');
            if (token) {
                try {
                    const response = await axios.post(
                        `${API_URL}/validate-token`,
                        { token },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                        }
                    );
                    if (response.data.valid) {
                        setUser(response.data.user);
                        setRemainingRequests(response.data.remainingRequests);
                    } else {
                        handleLogout();
                    }
                } catch (err) {
                    console.error('Token validation failed:', err);
                    handleLogout();
                }
            }
        };

        validateExistingToken();
    }, [API_URL]);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                remainingRequests,
                setRemainingRequests,
                isDark,
                setIsDark,
                showGoogleLogin,
                setShowGoogleLogin,
                handleLogout,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
