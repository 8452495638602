//RecipeExtractor.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { X as CloseIcon, Share2, Star, Instagram, Globe } from 'lucide-react';
import axios from 'axios';
import ReactGA from 'react-ga4'; // Changed to ReactGA4 for GA4 support
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import RecipeIngredients from "./components/recipeIngredients";
import RecipeInstructions from "./components/RecipeInstructions";
import UserContext from './components/UserContext';
import HistoryRecipes from "./components/HistoryRecipes";
import FavoriteRecipe from "./components/FavoriteRecipe";
import RandomRecipes from './components/RandomRecipes';


const API_URL = process.env.REACT_APP_API_URL;

// Conversion function remains the same
const convertToMetric = (amount, unit) => {
    const conversions = {
        teaspoon: {factor: 5.69, unit: 'g'},
        tablespoon: {factor: 14.175, unit: 'g'},
        fluidounce: {factor: 29.574, unit: 'ml'},
        cup: {factor: 236.588, unit: 'ml'},
        pint: {factor: 473.176, unit: 'ml'},
        quart: {factor: 946.353, unit: 'ml'},
        gallon: {factor: 3785.41, unit: 'ml'},
        ounce: {factor: 28.3495, unit: 'g'},
        pound: {factor: 453.592, unit: 'g'},
        inch: {factor: 2.54, unit: 'cm'},
        fahrenheit: {factor: (f) => (f - 32) * 5 / 9, unit: '°C'},
        f: {factor: (f) => (f - 32) * 5 / 9, unit: '°C'},
        tsp: {factor: 5, unit: 'g'},
        tbsp: {factor: 15, unit: 'g'},
        oz: {factor: 28.3495, unit: 'g'},
        lb: {factor: 453.592, unit: 'g'},
        teaspoons: {factor: 5.69, unit: 'g'},
        tablespoons: {factor: 14.175, unit: 'g'},
        ounces: {factor: 28.3495, unit: 'g'},
        cups: {factor: 236.588, unit: 'ml'},
        pints: {factor: 473.176, unit: 'ml'},
        quarts: {factor: 946.353, unit: 'ml'},
        gallons: {factor: 3785.41, unit: 'ml'},
        pounds: {factor: 453.592, unit: 'g'},
        inches: {factor: 2.54, unit: 'cm'},
    };

    const normalizedUnit = unit.toLowerCase().replace('.', '');

    if (conversions[normalizedUnit]) {
        let convertedAmount;
        if (typeof conversions[normalizedUnit].factor === 'function') {
            convertedAmount = conversions[normalizedUnit].factor(parseFloat(amount));
        } else {
            convertedAmount = parseFloat(amount) * conversions[normalizedUnit].factor;
        }

        return {
            amount: convertedAmount.toFixed(2),
            unit: conversions[normalizedUnit].unit,
        };
    }

    return {amount, unit};
};

const RecipeExtractor = () => {
    const {
        user,
        setUser,
        remainingRequests,
        setRemainingRequests,
        isDark,
        handleLogout,
    } = useContext(UserContext);

    const navigate = useNavigate();
    const location = useLocation();
    const { recipeId } = useParams();
    const hasInitialized = useRef(false);

    const [url, setUrl] = useState('');
    const [recipe, setRecipe] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [useMetric, setUseMetric] = useState(false);
    const [history, setHistory] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [extractionTime, setExtractionTime] = useState(null);
    const [currentlyExtractedUrl, setCurrentlyExtractedUrl] = useState(null);
    const [consentGiven, setConsentGiven] = useState(null);
    const [isShared, setIsShared] = useState(false);
    const [placeholder, setPlaceholder] = useState('Paste any recipe URL from the web or Instagram');
    const [randomRecipes, setRandomRecipes] = useState([]);

    useEffect(() => {
        fetchRandomRecipes();

        if (location.pathname !== '/' || url) return;

        let isMounted = true; // To prevent state updates after unmounting

        const examples = [
            'https://www.allrecipes.com/recipe/chocolate-chip-cookies',
            'https://www.instagram.com/p/amazing-banana-bread',
            'https://www.foodnetwork.com/recipes/food-network-kitchen/classic-shrimp-scampi-8849846',
            'https://cooking.nytimes.com/recipes/1016230-robertas-pizza-dough',
            'https://www.bbcgoodfood.com/recipes/easy-chicken-curry'
        ];

        const DEFAULT_TEXT = 'Paste any recipe URL from the web or Instagram';

        const typingSpeed = 100; // milliseconds per character
        const erasingSpeed = 50;  // milliseconds per character
        const pauseBetweenExamples = 2000; // milliseconds
        const pauseAfterErasing = 1000;    // milliseconds
        const initialDelay = 5000;         // milliseconds

        const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        const typeText = async (text) => {
            for (let i = 1; i <= text.length; i++) {
                if (!isMounted) return;
                setPlaceholder(text.slice(0, i));
                await sleep(typingSpeed);
            }
        };

        const eraseText = async (text) => {
            for (let i = text.length; i >= 0; i--) {
                if (!isMounted) return;
                setPlaceholder(text.slice(0, i) || DEFAULT_TEXT);
                await sleep(erasingSpeed);
            }
        };

        const animatePlaceholder = async () => {
            await sleep(initialDelay);
            while (isMounted) {
                for (let example of examples) {
                    await typeText(example);
                    await sleep(pauseBetweenExamples);
                    await eraseText(example);
                    await sleep(pauseAfterErasing);
                }
            }
        };

        animatePlaceholder();

        return () => {
            isMounted = false; // Cleanup to prevent state updates after unmounting
        };
    }, [location.pathname, url]);

    const initializeGA = () => {
        ReactGA.initialize('G-ZWB52WC9T9', {
            gaOptions: {
                debug_mode: process.env.NODE_ENV !== 'production' // Enable debug mode in non-production environments
            }
        });

        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    };

    const handleExtractionError = (err) => {
        if (err.response?.status === 401) {
            setError('Session expired. Please sign in again.');
            handleLogout();
            localStorage.removeItem('recipeToken');
        } else if (err.response?.status === 400) {
            setError(err.response.data.error);
        } else if (err.response?.status === 429) {
            setError('Daily request limit reached. Please try again tomorrow.');
        } else {
            setError('Error extracting recipe. Please try again.');
        }
        console.error('Error:', err);
    };

    const handleConsentChange = (consent) => {
        setConsentGiven(consent);
        localStorage.setItem('analytics_consent', consent);

        if (window.gtag) {
            window.gtag('consent', 'update', {
                'analytics_storage': consent ? 'granted' : 'denied'
            });
        }

        if (consent) {
            initializeGA(); // Initialize analytics if consent is given
        }
    };

    // Add this new function to handle the share click with animation
    const handleShare = () => {
        navigator.clipboard.writeText(window.location.href);
        setIsShared(true);
        setTimeout(() => {
            setIsShared(false);
        }, 2000); // Reset after 2 seconds
    };

    // Modified event tracking functions
    const trackExtractRecipe = (url) => {
        if (consentGiven) {
            ReactGA.event({
                category: 'Recipe',
                action: 'Extract Recipe',
                label: url
            });
        }
    };

    const trackAddToFavorites = (recipeTitle) => {
        if (consentGiven) {
            ReactGA.event({
                category: 'User Interaction',
                action: 'Add to Favorites',
                label: recipeTitle
            });
        }
    };

    const trackLogin = () => {
        if (consentGiven) {
            ReactGA.event({
                category: 'User',
                action: 'Login',
                label: 'Google Login'
            });
        }
    };

    const loadRecipeById = async (id, authToken) => {
        if (!id) return;

        setLoading(true);
        setError('');

        try {
            const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
            const response = await axios.get(
                `${API_URL}/recipes/${id}`,
                { headers }
            );

            // Update both recipe and URL state
            setRecipe(response.data.recipe);
            setUrl(response.data.url);
            setCurrentlyExtractedUrl(response.data.url);

            if (response.data.remaining !== undefined) {
                setRemainingRequests(response.data.remaining);
            }
        } catch (err) {
            handleLoadError(err);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (hasInitialized.current) return;
        hasInitialized.current = true;

        // Load stored data
        const storedHistory = JSON.parse(localStorage.getItem('recipeHistory') || '[]');
        const storedFavorites = JSON.parse(localStorage.getItem('favoriteRecipes') || '[]');
        const storedConsent = localStorage.getItem('analytics_consent');
        const token = localStorage.getItem('recipeToken');

        setHistory(storedHistory);
        setFavorites(storedFavorites);

        if (storedConsent === 'true') {
            setConsentGiven(true);
        } else if (storedConsent === 'false') {
            setConsentGiven(false);
        } else {
            setConsentGiven(null);
        }

        if (storedConsent === 'true') {
            initializeGA();
        }


    }, []);

    useEffect(() => {
        if (recipeId) {
            const token = localStorage.getItem('recipeToken');
            const storedHistory = JSON.parse(localStorage.getItem('recipeHistory') || '[]');
            const existingRecipe = storedHistory.find(item => item.recipe._id === recipeId);

            if (existingRecipe) {
                // Load from history
                setRecipe(existingRecipe.recipe);
                setUrl(existingRecipe.url);
                setCurrentlyExtractedUrl(existingRecipe.url);
            } else {
                // Load from server
                loadRecipeById(recipeId, token);
            }
        }
    }, [recipeId]);

    // Clear URL parameters when navigating away from a recipe
    useEffect(() => {
        if (location.pathname === '/' && recipe) {
            cleanUrl();
        }
    }, [location.pathname]);


    const handleClearClick = () => {
        cleanUrl();
        navigate(`/`);
    };

    const ConsentBanner = ({onAccept, onDecline}) => (
        <div
            className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 shadow-lg p-4 print:hidden z-50">
            <div className="container mx-auto max-w-4xl flex flex-col sm:flex-row items-center justify-between gap-4">
                <div className="flex-1">
                    <p className="text-sm text-gray-700 dark:text-gray-300">
                        We use cookies to analyze site usage and improve your experience.{' '}
                        <Link to="/cookie-policy" className="text-blue-600 dark:text-blue-400 hover:underline">
                            Learn more about our cookie policy
                        </Link>
                    </p>
                </div>
                <div className="flex gap-3">
                    <button
                        onClick={onAccept}
                        className="px-4 py-2 bg-blue-600 dark:bg-blue-700 text-white text-sm font-medium rounded-md hover:bg-blue-700 dark:hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
                    >
                        Accept
                    </button>
                    <button
                        onClick={onDecline}
                        className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 text-sm font-medium rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
                    >
                        Decline
                    </button>
                </div>
            </div>
        </div>
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            setError('Please sign in to extract recipes');
            return;
        }

        if (remainingRequests === 0) {
            setError('Daily request limit reached. Please try again tomorrow.');
            return;
        }

        if (!url || !url.startsWith('http')) {
            setError('Please enter a valid URL starting with http:// or https://');
            return;
        }

        setLoading(true);
        setError('');
        setRecipe(null);
        setExtractionTime(null);
        setCurrentlyExtractedUrl(null);

        // Clean URL before new extraction
        navigate('/', { replace: true });

        const startTime = Date.now();
        const token = localStorage.getItem('recipeToken');

        trackExtractRecipe(url);

        try {
            const response = await axios.post(
                `${API_URL}/extract`,
                { url },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setRecipe(response.data.recipe);
            setRemainingRequests(response.data.remainingRequests);
            setCurrentlyExtractedUrl(url);
            addToHistory(url, response.data.recipe);

            // Update URL with recipe ID
            navigate(`/recipe/${response.data.recipeId}`, { replace: true });

            const endTime = Date.now();
            setExtractionTime((endTime - startTime) / 1000);
        } catch (err) {
            handleExtractionError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setUrl(newUrl);

        if (newUrl !== currentlyExtractedUrl) {
            setError('');
        }
    };

    const cleanUrl = () => {

        setRecipe(null);
        setUrl('');
        setCurrentlyExtractedUrl(null);
        setExtractionTime(null);
        setError('');

    };

    const handleHistoryClick = async (historyItem) => {
        setLoading(true);
        setError('');

        try {
            const recipeHistory = JSON.parse(localStorage.getItem('recipeHistory') || '[]');
            const recipe = recipeHistory.find(item => item.url === historyItem.url);

            if (recipe) {
                // If recipe exists in history, just set states and navigate
                setUrl(historyItem.url);
                setRecipe(recipe.recipe);
                setCurrentlyExtractedUrl(historyItem.url);
                return navigate(`/recipe/${recipe.recipe._id}`);
            }

            // Only if not in history, fetch from server
            const token = localStorage.getItem('recipeToken');
            const response = await axios.post(
                `${API_URL}/extract`,
                { url: historyItem.url },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setUrl(historyItem.url);
            setRecipe(response.data.recipe);
            setCurrentlyExtractedUrl(historyItem.url);
            navigate(`/recipe/${response.data.recipeId}`);
        } catch (err) {
            handleExtractionError(err);
        } finally {
            setLoading(false);
        }
    };

    const addToHistory = (newUrl, newRecipe) => {
        const historyItem = {
            url: newUrl,
            title: newRecipe.title,
            recipe: newRecipe,
        };
        const updatedHistory = [historyItem, ...history.filter((item) => item.url !== newUrl)].slice(0, 10);
        setHistory(updatedHistory);
        localStorage.setItem('recipeHistory', JSON.stringify(updatedHistory));
    };

    const addToFavorites = (newUrl, newRecipe) => {
        const favoriteItem = {
            url: newUrl,
            title: newRecipe.title,
            recipe: newRecipe,
        };
        const updatedFavorites = [favoriteItem, ...favorites.filter((item) => item.url !== newUrl)];
        setFavorites(updatedFavorites);
        localStorage.setItem('favoriteRecipes', JSON.stringify(updatedFavorites));

        trackAddToFavorites(newRecipe.title);
    };

    const removeFromHistory = (urlToRemove) => {
        const updatedHistory = history.filter((item) => item.url !== urlToRemove);
        setHistory(updatedHistory);
        localStorage.setItem('recipeHistory', JSON.stringify(updatedHistory));
    };

    const removeFromFavorites = (urlToRemove) => {
        const updatedFavorites = favorites.filter((item) => item.url !== urlToRemove);
        setFavorites(updatedFavorites);
        localStorage.setItem('favoriteRecipes', JSON.stringify(updatedFavorites));
    };

    const handleLoadError = (err) => {
        if (err.response?.status === 404) {
            setError('Recipe not found');
            navigate('/');
        } else if (err.response?.status === 401) {
            setError('Session expired. Please sign in again.');
            handleLogout();
            localStorage.removeItem('recipeToken');
        } else if (err.response?.status === 429) {
            setError('Daily request limit reached. Please try again tomorrow.');
        } else {
            setError('Error loading recipe');
            console.error('Error:', err);
        }
    };

    const isExtractButtonDisabled = loading || (currentlyExtractedUrl === url && recipe);
    const isFavorited = favorites.some(favorite => favorite.url === url);

    const handleFavoriteClick = () => {
        if (isFavorited) {
            removeFromFavorites(url);
        } else {
            addToFavorites(url, recipe);
        }
    };

    const isInstagramUrl = (url) => {
        return url.includes('instagram.com') || url.includes('instagr.am');
    };

    const getUrlIcon = () => {
        if (!url) return null;

        if (isInstagramUrl(url)) {
            return <Instagram size={18} className="text-pink-500" />;
        }
        return <Globe size={18} className="text-blue-500 dark:text-blue-400" />;
    };

    const fetchRandomRecipes = async () => {
        try {
            const response = await axios.get(`${API_URL}/recipes/random`);
            setRandomRecipes(response.data);
        } catch (error) {
            console.error('Error fetching random recipes:', error);
        }
    };



    return (
        <div
            className="container mx-auto px-4 py-8 print:w-[210mm] print:max-w-none print:px-0 print:mx-auto print:py-0">

            {!recipe && (
                <div className="w-full text-center mb-16 print:hidden px-4 sm:px-6">
                    {/* Primary Message */}
                    <h1 className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl
                         text-gray-800 dark:text-gray-200
                         font-light leading-relaxed">
                        Your favorite recipes from the web,{' '}
                        <span className="font-medium bg-gradient-to-r from-cyan-600 to-blue-600 dark:from-cyan-400 dark:to-blue-400
                               bg-clip-text text-transparent">
                        all in one place
                    </span>
                    </h1>

                    {/* Secondary Message */}
                    <p className="mt-6 text-base sm:text-lg xl:text-xl
                       text-gray-600 dark:text-gray-400
                       leading-relaxed">
                        Transform any{' '}
                        <span className="text-cyan-600 dark:text-cyan-400 font-medium">
                        online recipe
                    </span>{' '}
                        or{' '}
                        <span className="text-blue-600 dark:text-blue-400 font-medium">
                        Instagram post
                    </span>{' '}
                        into an easy-to-read, organized place
                    </p>
                </div>
            )}

            {/* Consent Banner */}
            {consentGiven === null && (
                <ConsentBanner
                    onAccept={() => handleConsentChange(true)}
                    onDecline={() => handleConsentChange(false)}
                />
            )}

            {/* Form */}
            <form onSubmit={handleSubmit} className="mb-8 print:hidden">
                <div className="mx-auto">
                    <div className="flex flex-col sm:flex-row gap-3">
                        <div className="relative flex-grow group">
                            {recipe && location.pathname.includes('/recipe/') ? (
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleShare();
                                    }}
                                    className="absolute left-3 top-1/2 -translate-y-1/2
                                    text-gray-400 hover:text-blue-500
                                    focus:outline-none
                                    rounded-full transition-colors duration-200 z-10
                                    w-5 h-5 flex items-center justify-center"
                                    title="Share recipe"
                                >
                                    <span className={`
                                        transform inline-block transition-all duration-200
                                        ${isShared ? 'scale-0 opacity-0' : 'scale-100 opacity-100'}
                                    `}>
                                        <Share2 size={18}/>
                                    </span>
                                    <span className={`
                                        absolute left-0 top-0
                                        transform inline-block transition-all duration-200
                                        ${isShared ? 'scale-100 opacity-100 text-green-500' : 'scale-0 opacity-0'}
                                    `}>
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                    </span>
                                </button>
                            ) : (
                                url && (
                                    <div
                                        className="absolute left-3 top-1/2 -translate-y-1/2 transition-all duration-200">
                                        {getUrlIcon()}
                                    </div>
                                )
                            )}
                            <input
                                className="w-full px-4 py-3.5 border-2 border-gray-200 dark:border-gray-700 rounded-xl
                                text-gray-700 dark:text-gray-300 placeholder-gray-400 dark:placeholder-gray-500
                                bg-white dark:bg-gray-800
                                shadow-sm
                                focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500
                                disabled:bg-gray-50 dark:disabled:bg-gray-900
                                disabled:text-gray-500 dark:disabled:text-gray-600
                                disabled:cursor-not-allowed
                                transition-all duration-200
                                group-hover:border-gray-300 dark:group-hover:border-gray-600
                                pl-9 text-base"
                                type="text"
                                placeholder={placeholder}
                                value={url}
                                onChange={handleUrlChange}
                                disabled={loading}
                                aria-label="Recipe URL input"
                            />
                            {url && (
                                <button
                                    type="button"
                                    onClick={handleClearClick}
                                    className="absolute right-3 top-1/2 -translate-y-1/2
                                    text-gray-400 hover:text-gray-600 dark:hover:text-gray-300
                                    focus:outline-none focus:text-gray-600
                                    transition-colors duration-200"
                                    aria-label="Clear URL"
                                >
                                    <CloseIcon size={18}/>
                                </button>
                            )}
                        </div>
                        <button
                            className={`px-6 py-3.5 rounded-xl font-medium
                            shadow-md hover:shadow-lg
                            transform transition-all duration-200 
                            focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 
                            dark:focus:ring-offset-gray-900
                            whitespace-nowrap
                            ${
                                isExtractButtonDisabled || !user
                                    ? 'bg-gray-100 dark:bg-gray-800 text-gray-400 dark:text-gray-500 border-2 border-gray-200 dark:border-gray-700 cursor-not-allowed'
                                    : 'bg-gradient-to-r from-cyan-500 to-blue-600 dark:from-cyan-600 dark:to-blue-700 hover:from-cyan-600 hover:to-blue-700 text-white'
                            }`}
                            type="submit"
                            disabled={isExtractButtonDisabled || !user}
                            title={!user ? 'Please sign in to extract recipes' : ''}
                        >
                            <span className="flex items-center gap-2">
                                {currentlyExtractedUrl === url && recipe ? (
                                    <>
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <span>Save Recipe</span>
                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6"/>
                                        </svg>
                                    </>
                                )}
                            </span>
                        </button>
                    </div>
                </div>
            </form>

            {/* Status Messages */}
            <div className="print:hidden">
                {loading && (
                    <p className="text-blue-500 dark:text-blue-400 italic text-center">
                        Extracting recipe...
                    </p>
                )}
                {error && (
                    <p className="text-red-500 dark:text-red-400 text-center">
                        {error}
                    </p>
                )}
                {extractionTime !== null && (
                    <p className="text-green-600 dark:text-green-400 text-center mb-4">
                        Recipe extracted in {extractionTime.toFixed(2)} seconds
                    </p>
                )}
            </div>

            {/* Recipe Output */}
            {recipe && (
                <div className="recipe-output mb-8">
                    <div className="flex items-center justify-center gap-2 mb-4">
                        <button
                            onClick={handleFavoriteClick}
                            className="print:hidden p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800
                                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                                dark:focus:ring-offset-gray-900"
                            aria-label={isFavorited ? "Remove from favorites" : "Add to favorites"}
                        >
                            {isFavorited ? (
                                <Star className="w-5 h-5 text-yellow-500 fill-yellow-500"/>
                            ) : (
                                <Star className="w-5 h-5 text-gray-400 dark:text-gray-500 hover:text-yellow-500"/>
                            )}
                        </button>
                        <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                            {recipe.title}
                        </h2>
                    </div>

                    {recipe.servings && (
                        <p className="text-lg mb-4 text-gray-700 dark:text-gray-300 text-center">
                            Servings: {recipe.servings}
                        </p>
                    )}

                    <div className="mb-4 flex print:hidden">
                        <label className="inline-flex items-center text-gray-700 dark:text-gray-300">
                            <input
                                type="checkbox"
                                className="form-checkbox dark:bg-gray-700 dark:border-gray-600"
                                checked={useMetric}
                                onChange={() => setUseMetric(!useMetric)}
                            />
                            <span className="ml-2">Use Grams</span>
                        </label>
                    </div>

                    <div className="grid min-[850px]:grid-cols-5 print:grid-cols-12 gap-6">
                        <div className="min-[850px]:col-span-2 print:col-span-5">
                            <RecipeIngredients
                                ingredients={recipe.ingredients}
                                useMetric={useMetric}
                                convertToMetric={convertToMetric}
                            />
                        </div>
                        <div className="min-[850px]:col-span-3 print:col-span-7">
                            <RecipeInstructions
                                instructions={recipe.instructions}
                                ingredients={recipe.ingredients}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Favorites Section */}
            {favorites.length > 0 && (
                <FavoriteRecipe
                    favorites={favorites}
                    handleHistoryClick={handleHistoryClick}
                    removeFromFavorites={removeFromFavorites}
                />
            )}

            {/* History Section */}
            {history.length > 0 && (
                <HistoryRecipes
                    history={history}
                    handleHistoryClick={handleHistoryClick}
                    removeFromHistory={removeFromHistory}
                />
            )}

            {randomRecipes.length > 0 && (
                <RandomRecipes recipes={randomRecipes} />
            )}
        </div>
    );
};

export default RecipeExtractor;