import React, { useEffect, useState, useMemo } from 'react';
import { Check, Share2, Trash2, Printer, ListChecks, Trash } from 'lucide-react';
import AddItemForm from './addItemForm';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getBaseUnit = (unit) => {
    if (!unit) return '';

    const unitMap = {
        'tablespoon': 'tbsp',
        'tablespoons': 'tbsp',
        'tbsp': 'tbsp',
        'tbs': 'tbsp',
        'teaspoon': 'tsp',
        'teaspoons': 'tsp',
        'tsp': 'tsp',
        'cup': 'cup',
        'cups': 'cup',
        'gram': 'g',
        'grams': 'g',
        'g': 'g',
        'kilogram': 'kg',
        'kilograms': 'kg',
        'kg': 'kg',
        'milliliter': 'ml',
        'milliliters': 'ml',
        'ml': 'ml',
        'liter': 'l',
        'liters': 'l',
        'l': 'l',
        'ounce': 'oz',
        'ounces': 'oz',
        'oz': 'oz',
        'pound': 'lb',
        'pounds': 'lb',
        'lb': 'lb',
        'lbs': 'lb',
        'piece': '',
        'pieces': '',
        'clove': '',
        'cloves': '',
        'item': '',
        'items': '',
        'unit': '',
        'units': ''
    };

    const normalizedUnit = unit.toLowerCase().trim();
    return unitMap[normalizedUnit] !== undefined ? unitMap[normalizedUnit] : unit;
};

const normalizeIngredientName = (name) => {
    return (typeof name !== 'undefined') ? name.toLowerCase().trim() : name;
};

const ShoppingList = () => {
    const [items, setItems] = useState([]);
    const [checkedItems, setCheckedItems] = useState(new Set());
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [shareMessage, setShareMessage] = useState('');

    const formatQuantity = (quantity, unit, isPrint = false) => {
        if (!quantity || !unit) return '';

        const baseUnit = getBaseUnit(unit);

        // Hide measurements that are small or not necessary to show
        if (
            baseUnit === 'tbsp' ||
            baseUnit === 'tsp' ||
            baseUnit === 'pinch' ||
            (baseUnit === 'cup' && quantity < 1) ||
            (baseUnit === '' && ['cube', 'egg'].includes(unit.toLowerCase())) ||
            ['oil', 'ketchup'].some(term => unit.toLowerCase().includes(term) ||
                term.includes(unit.toLowerCase()))
        ) {
            return '';
        }

        const formattedQuantity = quantity === Math.floor(quantity)
            ? quantity.toString()
            : quantity.toFixed(2);

        return baseUnit ? `${formattedQuantity} ${baseUnit} ` : `${formattedQuantity} `;
    };

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const token = localStorage.getItem('recipeToken');
            const response = await axios.get(`${API_URL}/shopping-list`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setItems(response.data.items || []);
        } catch (err) {
            setError('Failed to load shopping list');
            console.error('Error fetching items:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleAddItem = async (newItem) => {
        try {
            const token = localStorage.getItem('recipeToken');
            const response = await axios.post(`${API_URL}/shopping-list/items`, newItem, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const addedItem = {
                _id: response.data._id,
                name: response.data.name || newItem.name,
                quantity: response.data.quantity || newItem.quantity,
                unit: response.data.unit || newItem.unit,
                category: response.data.category || newItem.category || 'Other'
            };

            setItems(prevItems => {
                const updatedItems = [...prevItems];
                updatedItems.push(addedItem);
                return updatedItems;
            });

            await fetchItems();
        } catch (err) {
            console.error('Error adding item:', err);
        }
    };

    const handleDeleteItem = async (itemId) => {
        try {
            const token = localStorage.getItem('recipeToken');
            await axios.delete(`${API_URL}/shopping-list/items/${itemId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setItems(prevItems => prevItems.filter(item => item._id !== itemId));
        } catch (err) {
            console.error('Error deleting item:', err);
        }
    };

    const confirmDeleteAll = async () => {
        try {
            const token = localStorage.getItem('recipeToken');
            await axios.delete(`${API_URL}/shopping-list/items`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setItems([]);
            setShowDeleteConfirm(false);
        } catch (err) {
            console.error('Error clearing list:', err);
        }
    };

    const handleToggleItem = (itemId) => {
        const newChecked = new Set(checkedItems);
        if (newChecked.has(itemId)) {
            newChecked.delete(itemId);
        } else {
            newChecked.add(itemId);
        }
        setCheckedItems(newChecked);
    };

    const handlePrint = () => {
        window.print();
    };

    const handleDeleteAll = () => {
        setShowDeleteConfirm(true);
    };

    const groupedItems = useMemo(() => {
        if (!items || !items.length) {
            return [];
        }

        const groups = {};

        // Combine duplicate items within each category
        const combinedItems = items.reduce((acc, item) => {
            const category = item.category || 'Other';
            if (!acc[category]) {
                acc[category] = new Map();
            }

            const normalizedName = normalizeIngredientName(item.name);

            if (acc[category].has(normalizedName)) {
                const existingItem = acc[category].get(normalizedName);
                const combinedItem = {
                    ...existingItem,
                    name: item.name,
                    quantities: [...(existingItem.quantities || []), {
                        quantity: item.quantity,
                        unit: item.unit
                    }]
                };
                acc[category].set(normalizedName, combinedItem);
            } else {
                acc[category].set(normalizedName, {
                    ...item,
                    quantities: [{
                        quantity: item.quantity,
                        unit: item.unit
                    }]
                });
            }

            return acc;
        }, {});

        Object.entries(combinedItems).forEach(([category, itemMap]) => {
            groups[category] = Array.from(itemMap.values());
        });

        return Object.entries(groups).sort(([a], [b]) => a.localeCompare(b));
    }, [items]);

    // Calculate total number of items for column distribution
    const totalItems = useMemo(() => {
        return groupedItems.reduce((sum, [_, items]) => sum + items.length, 0);
    }, [groupedItems]);

    // Split items into three roughly equal columns for printing
    const columnizedItems = useMemo(() => {
        const columns = [[], [], []];
        let currentColumn = 0;
        let currentCount = 0;
        const itemsPerColumn = Math.ceil(totalItems / 3);

        groupedItems.forEach(([category, items]) => {
            if (currentCount + items.length > itemsPerColumn && currentColumn < 2) {
                currentColumn++;
                currentCount = 0;
            }

            columns[currentColumn].push([category, items]);
            currentCount += items.length;
        });

        return columns;
    }, [groupedItems, totalItems]);

    if (loading) {
        return <div className="text-gray-700 dark:text-gray-300">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-600 dark:text-red-400">{error}</div>;
    }

    if (!items || !items.length) {
        return (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700 p-8">
                <AddItemForm onAddItem={handleAddItem} />
                <div className="text-center">
                    <ListChecks className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" />
                    <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-white">No items</h3>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">Your shopping list is empty.</p>
                </div>
            </div>
        );
    }

    const generateShareableList = () => {
        if (!items.length) return '';

        const groupedItems = {};
        items.forEach(item => {
            const category = item.category || 'Other';
            if (!groupedItems[category]) {
                groupedItems[category] = [];
            }
            groupedItems[category].push(item);
        });

        let shareText = '📝 Shopping List\n\n';

        Object.entries(groupedItems)
            .sort(([a], [b]) => a.localeCompare(b))
            .forEach(([category, categoryItems]) => {
                shareText += `${category}:\n`;
                categoryItems.forEach(item => {
                    const quantity = formatQuantity(item.quantity, item.unit, true);
                    shareText += `• ${quantity}${item.name}\n`;
                });
                shareText += '\n';
            });

        return shareText;
    };

    const handleShare = async () => {
        const shareText = generateShareableList();

        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Shopping List',
                    text: shareText
                });
            } catch (error) {
                if (error.name !== 'AbortError') {
                    fallbackShare(shareText);
                }
            }
        } else {
            fallbackShare(shareText);
        }
    };

    const fallbackShare = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setShareMessage('List copied to clipboard!');
                setTimeout(() => setShareMessage(''), 3000);
            })
            .catch(() => {
                setShareMessage('Failed to copy list');
                setTimeout(() => setShareMessage(''), 3000);
            });
    };

    return (
        <div
            className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700 print:shadow-none print:border-0">
            <div
                className="bg-gray-100 dark:bg-gray-900 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 print:shadow-none print:border-0">
                <div className="p-4 border-b border-gray-200 dark:border-gray-700 print:p-6 print:border-b-2">
                    <div
                        className="flex flex-col sm:flex-row sm:items-center gap-3 justify-between print:!flex-row print:!items-center">
                        <div className="flex items-center gap-2">
                            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                                Shopping List
                            </h3>
                            <span
                                className="text-sm font-normal text-gray-600 dark:text-gray-300 bg-gray-200 dark:bg-gray-800 px-2 py-0.5 rounded-full print:text-black">
                    {items.length} items
                </span>
                        </div>
                        <div className="flex items-center gap-2">
                            {items.length > 0 && (
                                <>
                                    <button onClick={handleDeleteAll}
                                            className="print:hidden inline-flex items-center gap-2 px-3 py-2 text-sm font-medium
                                text-red-600 dark:text-red-400 bg-red-100 dark:bg-red-900 border border-red-300 dark:border-red-800 rounded-md hover:bg-red-200 dark:hover:bg-red-800">
                                        <Trash size={16}/>
                                        Clear All
                                    </button>
                                    <button onClick={handleShare}
                                            className="print:hidden inline-flex items-center gap-2 px-3 py-2 text-sm font-medium
                                text-gray-800 dark:text-gray-300 bg-gray-200 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600">
                                        <Share2 size={16}/>
                                        Share
                                    </button>
                                </>
                            )}
                            <button onClick={handlePrint}
                                    className="print:hidden inline-flex items-center gap-2 px-3 py-2 text-sm font-medium
                        text-gray-800 dark:text-gray-300 bg-gray-200 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600">
                                <Printer size={16}/>
                                Print
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {showDeleteConfirm && (
                <div
                    className="p-4 bg-red-50 dark:bg-red-900/20 border-b border-red-100 dark:border-red-800 print:hidden">
                    <div className="flex items-center justify-between">
                        <p className="text-sm text-red-600 dark:text-red-400">
                            Are you sure you want to clear the entire list?
                        </p>
                        <div className="flex gap-2">
                            <button
                                onClick={() => setShowDeleteConfirm(false)}
                                className="px-3 py-1 text-sm font-medium text-gray-600 dark:text-gray-300
                                    bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600
                                    rounded-md hover:bg-gray-50 dark:hover:bg-gray-600"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmDeleteAll}
                                className="px-3 py-1 text-sm font-medium text-white
                                    bg-red-600 dark:bg-red-500 rounded-md
                                    hover:bg-red-700 dark:hover:bg-red-600"
                            >
                                Clear All
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <AddItemForm onAddItem={handleAddItem}/>


            <div className="print:hidden divide-y divide-gray-100 dark:divide-gray-700">
                {groupedItems.map(([category, categoryItems]) => (
                    <div key={category} className="print:mb-6 last:print:mb-0">
                        <div className="px-4 py-2 bg-gray-50 dark:bg-gray-800/50
                            font-medium text-gray-700 dark:text-gray-300
                            print:bg-transparent print:px-0 print:py-1 print:border-b print:border-black">
                            <span className="print:text-lg print:font-bold">{category}</span>
                            <span className="text-sm font-normal text-gray-500 dark:text-gray-400 ml-2 print:hidden">
                                ({categoryItems.length})
                            </span>
                        </div>
                        <ul className="divide-y divide-gray-100 dark:divide-gray-700 print:divide-y-0">
                            {categoryItems.map((item) => (
                                <li key={item._id || `${item.name}-${item.quantity}`}>
                                    <div
                                        className="flex items-center px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-700 print:hover:bg-transparent print:px-0 print:py-1.5 group">
                                        <div className="flex-shrink-0 mr-3 print:hidden">
                                            <div
                                                onClick={() => handleToggleItem(item._id)}
                                                className={`w-5 h-5 rounded border transition-all duration-200 flex items-center justify-center cursor-pointer
                                                    ${checkedItems.has(item._id)
                                                    ? 'bg-green-500 border-green-500'
                                                    : 'border-gray-300 dark:border-gray-500 group-hover:border-gray-400 dark:group-hover:border-gray-400'
                                                }`}
                                            >
                                                {checkedItems.has(item._id) && (
                                                    <Check size={14} className="text-white"/>
                                                )}
                                            </div>
                                        </div>

                                        <span className={`flex-grow ${
                                            checkedItems.has(item._id)
                                                ? 'text-gray-400 line-through dark:text-gray-500 print:no-underline print:text-black'
                                                : 'text-gray-700 dark:text-gray-300'
                                        }`}>
                                            <span className="hidden print:inline">
                                                {formatQuantity(item.quantity, item.unit, true)}
                                            </span>
                                            <span className="print:hidden">
                                                {formatQuantity(item.quantity, item.unit, false)}
                                            </span>
                                            <span className="print:text-black">
                                                {item.name}
                                            </span>
                                        </span>

                                        {handleDeleteItem && (
                                            <button
                                                onClick={() => handleDeleteItem(item._id)}
                                                className="print:hidden ml-2 text-gray-400 hover:text-red-500 dark:hover:text-red-400 print:hidden sm:opacity-0 sm:group-hover:opacity-100 transition-opacity"
                                                aria-label={`Delete ${item.name}`}
                                            >
                                                <Trash2 size={16}/>
                                            </button>
                                        )}

                                        <span className="hidden print:inline-block print:ml-4 print:w-6">□</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            <div className="hidden print:block print:mt-4">
                <div className="grid grid-cols-3 gap-x-8">
                    {columnizedItems.map((column, columnIndex) => (
                        <div key={columnIndex} className="break-inside-avoid">
                            {column.map(([category, items]) => (
                                <div key={category} className="mb-4">
                                    <div className="font-bold text-black border-b border-black mb-2">
                                        {category}
                                    </div>
                                    <ul className="space-y-1">
                                        {items.map((item) => (
                                            <li key={item._id || `${item.name}-${item.quantity}`}
                                                className="flex items-center justify-between">
                                                <span>
                                                    {formatQuantity(item.quantity, item.unit, true)}
                                                    {item.name}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            {checkedItems.size > 0 && (
                <div className="p-3 bg-gray-50 dark:bg-gray-800/50
                    border-t border-gray-100 dark:border-gray-700
                    text-sm text-gray-500 dark:text-gray-400 print:hidden">
                    {checkedItems.size} of {items.length} items checked
                </div>
            )}
        </div>
    );
};

export default ShoppingList;
